import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";
import "../CatPage/style.css";
import Header from "../Header";
import Footer from "../Footer";
import ScrollToTop from "../ScrollToTop";
import useIsMobile from "../../hooks/resize";

const CatPage = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const category = "უახლესი ამბები";
  const [news, setNews] = useState([]);
  const [visibleNews, setVisibleNews] = useState(16);
  const [adData, setAdData] = useState(null); // State to hold the ad data

  useEffect(() => {
    console.log("Category:", category);

    fetch(`http://127.0.0.1:8000/api/blogpages/?category=${category}`)
      .then((response) => response.json())
      .then((data) => {
        const sortedData = data.sort((a, b) => {
          const dateA = new Date(`${a.date}T${a.time}`);
          const dateB = new Date(`${b.date}T${b.time}`);
          return dateB - dateA;
        });

        setNews(sortedData);
      })
      .catch((error) => console.error("Error fetching category news:", error));

    // Fetch the advertisement data from your API
    fetch("http://127.0.0.1:8000/api/advertisements/")
      .then((response) => response.json())
      .then((data) => {
        // Find the advertisement with ad_number 4
        const ad = data.find((ad) => ad.ad_number === 4);
        setAdData(ad);
      })
      .catch((error) => console.error("Error fetching advertisement:", error));
  }, [category]);

  const loadMore = () => {
    setVisibleNews((prevVisibleNews) => prevVisibleNews + 12);
  };

  const handleNewsClick = (newsItem) => {
    const slug = slugify(newsItem.title, { lower: true, strict: true });
    navigate(`/news/${slug}`, {
      state: { newsItem, category },
    });
  };

  const circleAndLineColor = category === "უახლესი ამბები" ? "#4f46e5" : "red";

  // Determine how many items to show in the slider
  const sliderItemsCount = 5;
  const sliderItems = news.slice(0, sliderItemsCount);
  const cardItems = news.slice(sliderItemsCount);

  // Function to determine the media type
  const renderAdMedia = (url) => {
    const extension = url.split(".").pop().toLowerCase();
    if (["mp4", "webm", "ogg"].includes(extension)) {
      return (
        <video autoPlay loop muted playsInline className="ad-media">
          <source src={url} type={`video/${extension}`} />
          Your browser does not support the video tag.
        </video>
      );
    } else if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
      return <img src={url} alt="Advertisement" className="ad-media" />;
    } else {
      return <p>Unsupported media type</p>;
    }
  };

  return (
    <>
      <Header />
      <div className="main-container3">
        <div className="categ3">
          <div
            className="circle3"
            style={{ backgroundColor: circleAndLineColor }}
          ></div>
          <p className="human3">{category}</p>
        </div>
        <div
          className="line3"
          style={{ backgroundColor: circleAndLineColor }}
        ></div>

        {/* Container for Swiper Slider and Advertisement */}
        <div className="slider-ad-container">
          {/* Add the Swiper Slider here */}
          <div className="swiperSlider">
            <Swiper
              direction={"vertical"}
              pagination={{
                clickable: true,
                el: ".swiper-pagination", // Use custom pagination class
                bulletClass: "swiper-pagination-bullet", // Custom bullet class
                bulletActiveClass: "swiper-pagination-bullet-active", // Custom active bullet class
              }}
              autoplay={{
                delay: 5000, // Change slide every 5 seconds
                disableOnInteraction: false, // Continue autoplay after user interaction
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
            >
              {sliderItems.map((newsItem, index) => (
                <SwiperSlide
                  key={index}
                  onClick={() => handleNewsClick(newsItem)}
                >
                  <div className="slide-content">
                    <img
                      src={newsItem.image.file}
                      alt={newsItem.title}
                      className="slide-image"
                    />
                    <div className="black-overlay"></div>
                    <div className="text-overlay">
                      <p className="slide-date">{newsItem.date}</p>
                      <h2 className="slide-title">{newsItem.title}</h2>
                      <p className="slide-intro">{newsItem.intro}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              <div className="swiper-pagination"></div>
            </Swiper>
          </div>

          {/* Advertisement Section */}
          <div className="advertisement">
            {adData ? (
              <a href={adData.link} target="_blank" rel="noopener noreferrer">
                {renderAdMedia(adData.ad_media_url)}
              </a>
            ) : (
              <p>Loading advertisement...</p>
            )}
          </div>
        </div>

        <div className="news-container3">
          {cardItems.slice(0, visibleNews).map((article, index) => (
            <div
              className="news-card3"
              key={index}
              onClick={() => handleNewsClick(article)}
              style={{ cursor: "pointer" }}
            >
              <img src={article.image.file} alt={article.title} />
              <div className="card-content3">
                <h2>{article.title}</h2>
                <p className="description3">{article.intro}</p>
                <p className="date-of-card3">{article.date}</p>
                <a className="read-more3">სრულად →</a>
              </div>
            </div>
          ))}
        </div>

        {/* Conditionally render the "მეტის ჩვენება" button */}
        {visibleNews < news.length && (
          <div className="button-container3">
            <button className="load-more3" onClick={loadMore}>
              მეტის ჩვენება
            </button>
          </div>
        )}
      </div>
      <ScrollToTop />
      <Footer />
    </>
  );
};

export default CatPage;
