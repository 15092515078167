import React, { useState, useEffect } from "react";
import "./styleAd.css";

const MainAd = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [adMediaUrl, setAdMediaUrl] = useState(null);
  const [adLink, setAdLink] = useState(null);

  useEffect(() => {
    // Fetch the advertisement video URL and link from the API
    const fetchAdData = async () => {
      try {
        const response = await fetch(
          "http://127.0.0.1:8000/api/advertisements/"
        );
        const data = await response.json();
        if (data.length > 0) {
          setAdMediaUrl(data[0].ad_media_url);
          setAdLink(data[0].link);
        }
      } catch (error) {
        console.error("Error fetching advertisement data:", error);
      }
    };

    fetchAdData();
  }, []);

  useEffect(() => {
    if (!isClosed) {
      const timer = setTimeout(() => {
        setIsVisible(true);
        console.log("Showing advertisement");
      }, 7000); // 7 seconds

      return () => clearTimeout(timer);
    }
  }, [isClosed]);

  useEffect(() => {
    let reappearTimer;
    if (isClosed) {
      reappearTimer = setTimeout(() => {
        setIsVisible(true);
        setIsClosed(false); // Reset the closed state
      }, 10000); // 10 seconds

      return () => clearTimeout(reappearTimer);
    }
  }, [isClosed]);

  const handleClose = () => {
    setIsVisible(false);
    setIsClosed(true); // Set as closed so it doesn't reappear immediately
  };

  const handleAdClick = () => {
    if (adLink) {
      window.open(adLink, "_blank");
    }
  };

  if (!isVisible || !adMediaUrl) {
    return null;
  }

  return (
    <>
      <div className="main-ad-cube">
        <video
          src={adMediaUrl}
          autoPlay
          loop
          className="reklama1"
          onClick={handleAdClick}
          style={{ cursor: "pointer" }}
        />
        <span className="close-btn" onClick={handleClose}>
          &times;
        </span>
      </div>
    </>
  );
};

export default MainAd;
