import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import Header from "../Header";
import ScrollToTop from "../ScrollToTop";
import Footer from "../Footer";
import slugify from "slugify"; // Import slugify to create URL slugs
import "./style.css";

const TagsPage = () => {
  const { tagName } = useParams(); // Get the slugified tag from the URL
  const location = useLocation(); // Access location to get the original tag
  const navigate = useNavigate(); // To navigate to the news page
  const [newsItems, setNewsItems] = useState([]);
  const originalTag = location.state?.originalTag; // Get the original tag from state

  useEffect(() => {
    window.scrollTo(0, 0);
    if (originalTag) {
      // Fetch news based on the original tag
      fetch(
        `http://127.0.0.1:8000/api/blogpages/tag/${encodeURIComponent(
          originalTag
        )}/`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setNewsItems(data); // Set the state with the fetched news
        })
        .catch((error) => console.error("Error fetching news by tag:", error));
    }
  }, [originalTag]);

  // Function to handle clicking on a news item
  const handleNewsClick = (newsItem) => {
    const slug = slugify(newsItem.title, { lower: true, strict: true }); // Create a slug for the news title
    navigate(`/news/${slug}`, { state: { newsItem } }); // Navigate to the news page
  };

  return (
    <>
      <Header />
      <div className="main-container4">
        {/* Tag heading */}
        <div className="categ4">
          <h1
            className="text-3xl font-bold text-indigo-600 bg-white p-3"
            style={{ borderRadius: "12px", width: "100%" }}
          >
            {originalTag}
          </h1>{" "}
          {/* Display the original tag */}
        </div>
        <div className="line4"></div>

        {/* Display news cards */}
        <div className="news-container4">
          {newsItems.length > 0 ? (
            newsItems.map((news) => (
              <div
                key={news.custom_order}
                className="news-card4"
                style={{ cursor: "pointer" }}
                onClick={() => handleNewsClick(news)} // Attach the click event handler
              >
                <img src={news.image.file} alt={news.title} />
                <div className="card-content4">
                  <h2>{news.title}</h2>
                  <p className="description4">{news.intro}</p>
                  <p className="date-of-card4">
                    {new Date(news.date).toLocaleDateString()}
                  </p>
                  <a className="read-more4">სრულად →</a>
                </div>
              </div>
            ))
          ) : (
            <p>No news items found for this tag.</p> // Fallback message if no news items are found
          )}
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </>
  );
};

export default TagsPage;
