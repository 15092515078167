import React from "react";
import "./NewsCard.css";
import useIsMobile from "../../../../hooks/resize";

export default function NewsCard({ image, title, date, dot, category_name }) {
  const isMobile = useIsMobile();

  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength - 3) + "...";
  }

  // Use the title prop for the original text
  const truncatedText = truncateText(title, isMobile ? 55 : 100);

  return (
    <div className="news_card">
      <div
        className="slider_img"
        style={{ backgroundImage: `url(${image.file})` }}
      ></div>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "8px",
            paddingTop: "5px",
          }}
        >
          <div
            style={{
              backgroundColor: dot,
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              marginRight: "5px",
            }}
          ></div>
          <span className="category-name12">{category_name}</span>
        </div>
        <h4 className="news_card_title">{truncatedText}</h4>
      </div>
      <div className="date">{date}</div>
    </div>
  );
}
