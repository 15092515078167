import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import NewsItem from "./NewsItem";
import "./NewsCategory.css";
import useIsMobile from "../../../hooks/resize";
import slugify from "slugify"; // Import slugify

export default function NewsCategory() {
  const [newsItems, setNewsItems] = useState([]);
  const [visibleNews, setVisibleNews] = useState(8); // Initial number of visible news items
  const isMobile = useIsMobile();
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    // Fetch the news data from your API
    fetch("http://127.0.0.1:8000/api/blogpages/")
      .then((response) => response.json())
      .then((data) => {
        // Sort the news data by date and time (newest first)
        const sortedData = data.sort((a, b) => {
          const dateA = new Date(`${a.date}T${a.time}`);
          const dateB = new Date(`${b.date}T${b.time}`);
          return dateB - dateA;
        });

        // Set the news items to state
        setNewsItems(sortedData);
      })
      .catch((error) => console.error("Error fetching news:", error));
  }, []);

  const loadMore = () => {
    setVisibleNews((prevVisibleNews) => prevVisibleNews + 8); // Increase the number of visible items
  };

  const handleNewsClick = (newsItem) => {
    const slug = slugify(newsItem.title, { lower: true, strict: true });
    navigate(`/news/${slug}`, {
      state: { newsItem },
    });
  };

  return (
    <section className="news_category_section_wrapper">
      <div className="title_categ">
        <h1>ყველა</h1>
      </div>
      <div className="news_grid">
        {newsItems.slice(0, visibleNews).map((newsItem) => (
          <div
            key={newsItem.id}
            onClick={() => handleNewsClick(newsItem)}
            style={{ cursor: "pointer" }}
          >
            <NewsItem
              title={newsItem.title}
              description={newsItem.intro}
              date={newsItem.date}
              src={newsItem.image?.file}
              dot={newsItem.dot}
              category={newsItem.category_name}
            />
          </div>
        ))}
      </div>

      {/* Conditionally render the "მეტის ჩვენება" button */}
      {visibleNews < newsItems.length && (
        <div className="button-container3">
          <button className="load-more3" onClick={loadMore}>
            მეტის ჩვენება
          </button>
        </div>
      )}
    </section>
  );
}
