import "./NewsItem.css";
import xvicha from "../../../../Images/Xvicha.jpg";
import useIsMobile from "../../../../hooks/resize";

export default function NewsItem({
  title,
  description,
  date,
  src,
  dot,
  category,
}) {
  const isMobile = useIsMobile();

  // Function to truncate the description if it exceeds 150 characters
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className="news_item_wrapper">
      <div className="content">
        <div
          className="image_container"
          style={{ backgroundImage: `url(${src || xvicha})` }}
        ></div>
        <div className="content_wrapper">
          <h5>
            <span style={{ backgroundColor: dot, color: "white" }}></span>
            {category}
          </h5>
          <p>{truncateText(description, 110)}</p>
        </div>
      </div>
      <div className="line"></div>
      <p className="dateItem">{date || "26/04/2024"}</p>
    </div>
  );
}
