import React from "react";
import Header from "../components/Header";
import Main from "../components/Main";
import NewsPage from "../components/NewsPage"
import CatPage from "../components/CatPage";
// import Carousel from "../../src/components/Carousel"

function Home() {
  return (
    <>
      <Header />
      <Main />
      {/* <NewsPage /> */}
      {/* <CatPage /> */}
    </>
  );
}

export default Home;
