import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import slugify from 'slugify';
import Header from './index';
import './search.css'; // Ensure this imports your styles

const SearchPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('query');
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (query) {
      const fetchUrl = `http://127.0.0.1:8000/api/search/?query=${query}`;
      fetch(fetchUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setResults(data || []);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setResults([]);
        });
    }
  }, [query]);

  // Helper function to truncate text
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  // Function to handle news item click
  const handleNewsClick = (newsItem) => {
    const slug = slugify(newsItem.title, { lower: true, strict: true });
    navigate(`/news/${slug}`, { state: { newsItem } });
  };

  return (
    <>
      <Header />
      <div className="main-container">
        <div className="max-w-7xl mx-auto">
          <header className="bg-white" style={{ borderRadius: "8px", padding: "15px", marginBottom: "20px", border: "1px solid #ddd" }}>
            <h1 className="text-3xl font-bold text-gray-900" style={{ padding: "0", margin: "0" }}>
              ძიების შედეგები: <span className="text-indigo-600">{query}</span>
            </h1>
          </header>
          <div className="news-container">
            {results.length > 0 ? (
              results.map((result, index) => (
                <div key={index} className="news-card" onClick={() => handleNewsClick(result)}>
                  <img src={result.image.file} alt={result.image.title} />
                  <div className="card-content">
                    <h2>{truncateText(result.title, 60)}</h2>
                    <p className="intro">{truncateText(result.intro, 100)}</p>
                    <p className="date"><FontAwesomeIcon icon={faCalendarAlt} /> {new Date(result.date).toLocaleDateString()}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>სამწუხაროდ, შედეგები არ მოიძებნა.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchPage;
