import React, { useState, useEffect } from "react";
// Import FontAwesome components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons"; // Import specific icon
import "../Calendar/style.css";

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [displayDates, setDisplayDates] = useState([]);

  // Function to update the current date every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000); // Update every second

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  // Calculate dates to display: two past, one current, and two future
  useEffect(() => {
    const dates = [];
    const today = new Date(currentDate);

    // Add two past dates
    for (let i = 2; i > 0; i--) {
      const pastDate = new Date(today);
      pastDate.setDate(today.getDate() - i);
      dates.push(pastDate);
    }

    // Add current date
    dates.push(today);

    // Add two future dates
    for (let i = 1; i <= 2; i++) {
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + i);
      dates.push(futureDate);
    }

    setDisplayDates(dates);
  }, [currentDate]);

  // Manual mapping for shortened Georgian weekday names
  const weekdays = ["ორშ", "სამ", "ოთხ", "ხუთ", "პარ", "შაბ", "კვ"];

  // Manual mapping for full Georgian month names
  const months = [
    "იანვარი",
    "თებერვალი",
    "მარტი",
    "აპრილი",
    "მაისი",
    "ივნისი",
    "ივლისი",
    "აგვისტო",
    "სექტემბერი",
    "ოქტომბერი",
    "ნოემბერი",
    "დეკემბერი",
  ];

  return (
    <div className="calendar-container">
      {/* Icon and Month on the same line */}
      <div className="icon-month-container">
        <div className="month-display">
          {months[currentDate.getMonth()]}{" "}
          {/* Display current month using Georgian month list */}
        </div>
        <FontAwesomeIcon icon={faCalendar} className="calendar-icon" />
      </div>

      <div className="dates-row">
        {displayDates.map((date, index) => (
          <div
            key={index}
            className={`date-item ${
              date.toDateString() === currentDate.toDateString()
                ? "highlight"
                : ""
            }`}
          >
            <p
              className="weekday-text"
              style={{
                color:
                  date.toDateString() === currentDate.toDateString()
                    ? "white" // White for current date
                    : "#4f46e5", // Blue for other dates
                fontWeight: "700",
              }}
            >
              {weekdays[date.getDay()]} {/* Shortened Georgian day name */}
            </p>
            <p className="day-text">{date.getDate()}</p>
            {date.toDateString() === currentDate.toDateString() && (
              <div className="dot"></div> // Dot for the highlighted date
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
