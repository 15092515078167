import React from "react";
import "../Footer/style.css";
// import { SocialIcon } from "react-social-icons";
// import "react-social-icons/facebook";
import useIsMobile from "../../hooks/resize";

const Footer = () => {
  const isMobile = useIsMobile();
  return (
    <>
      {isMobile ? (
        // Add the mobile-specific footer content here
        <>
          <footer className="whole_footer">
            <div className="container">
              <div className="platform">
                <h1>Zmna.ge</h1>
                <p>
                  უახლესი ამბები ჩვენს <br /> პლატფორმაზე
                </p>
                <div className="socials">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="facebook h-7 w-7"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="instagram h-7 w-7"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </div>
              </div>

              <div className="categories_list">
                <h1>კატეგორიები</h1>
                <div className="lists">
                  <div className="left_list">
                    <ul>
                      <div
                        className="politics"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="politics_circle"></div>
                        <li>პოლიტიკა</li>
                      </div>
                      <div
                        className="society"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="society_circle"></div>
                        <li>საზოგადოება</li>
                      </div>
                      <div
                        className="health"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="health_circle"></div>
                        <li>ჯანმრთელობა</li>
                      </div>
                      <div
                        className="economy"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="economy_circle"></div>
                        <li>ეკონომიკა</li>
                      </div>
                      <div
                        className="culture"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="culture_circle"></div>
                        <li>კულტურა</li>
                      </div>
                      <div
                        className="sport"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="sport_circle"></div>
                        <li>სპორტი</li>
                      </div>
                    </ul>
                  </div>
                  <div className="right_list">
                    <ul>
                      <div
                        className="city"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="city_circle"></div>
                        <li>ჩემი ქალაქი</li>
                      </div>
                      <div
                        className="world"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="world_circle"></div>
                        <li>მსოფლიო</li>
                      </div>
                      <div
                        className="interview"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="interview_circle"></div>
                        <li>ინტერვიუ</li>
                      </div>
                      <div
                        className="broadcast"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="broadcast_circle"></div>
                        <li>გადაცემები</li>
                      </div>
                      <div
                        className="videoblog"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="videoblog_circle"></div>
                        <li>ვიდეობლოგი</li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="info">
                <h1>ინფორმაცია</h1>
                <p style={{ cursor: "pointer" }}>ჩვენ შესახებ</p>
              </div>
            </div>
            <div className="footer_line"></div>
            <div className="copyright">
              <p>
                Developed by <span>გარგარი • GarGari</span> © - 2024
              </p>
            </div>
          </footer>
        </>
      ) : (
        <>
          <footer className="whole_footer">
            <div className="container">
              <div className="platform">
                <h1>Zmna.ge</h1>
                <p>
                  უახლესი ამბები ჩვენს <br /> პლატფორმაზე
                </p>
                <div className="socials">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="facebook h-7 w-7"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="instagram h-7 w-7"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </div>
              </div>

              <div className="categories_list">
                <h1>კატეგორიები</h1>
                <div className="lists">
                  <div className="left_list">
                    <ul>
                      <div
                        className="politics"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="politics_circle"></div>
                        <li>პოლიტიკა</li>
                      </div>
                      <div
                        className="society"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="society_circle"></div>
                        <li>საზოგადოება</li>
                      </div>
                      <div
                        className="health"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="health_circle"></div>
                        <li>ჯანმრთელობა</li>
                      </div>
                      <div
                        className="economy"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="economy_circle"></div>
                        <li>ეკონომიკა</li>
                      </div>
                      <div
                        className="culture"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="culture_circle"></div>
                        <li>კულტურა</li>
                      </div>
                      <div
                        className="sport"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="sport_circle"></div>
                        <li>სპორტი</li>
                      </div>
                    </ul>
                  </div>
                  <div className="right_list">
                    <ul>
                      <div
                        className="city"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="city_circle"></div>
                        <li>ჩემი ქალაქი</li>
                      </div>
                      <div
                        className="world"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="world_circle"></div>
                        <li>მსოფლიო</li>
                      </div>
                      <div
                        className="interview"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="interview_circle"></div>
                        <li>ინტერვიუ</li>
                      </div>
                      <div
                        className="broadcast"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="broadcast_circle"></div>
                        <li>გადაცემები</li>
                      </div>
                      <div
                        className="videoblog"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                        }}
                      >
                        <div className="videoblog_circle"></div>
                        <li>ვიდეობლოგი</li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="info">
                <h1>ინფორმაცია</h1>
                <p style={{ cursor: "pointer" }}>ჩვენ შესახებ</p>
              </div>
            </div>
            <div className="footer_line"></div>
            <div className="copyright">
              <p>
                Developed by <span className="devs">გარგარი • GarGari</span> © -
                2024
              </p>
            </div>
          </footer>
        </>
      )}
    </>
  );
};

export default Footer;
