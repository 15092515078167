import React, { useState } from "react";
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import {
  XMarkIcon,
  ChevronDownIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline";
import "../Header/style.css";
import logo from "../../icons/ZmnaLogo.svg";
import SearchModal from "./SearchModal ";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import slugify from "slugify"; // Import slugify

const products = [
  { name: "ჯანმრთელობა" },
  { name: "ეკონომიკა" },
  { name: "კულტურა" },
  { name: "სპორტი" },
  { name: "ჩემი ქალაქი" },
  { name: "მსოფლიო" },
  { name: "ინტერვიუ" },
  { name: "გადაცემები" },
  { name: "ვიდეობლოგი" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    let categorySlug;

    // Custom slug handling for specific categories
    if (category === "მსოფლიო") {
      categorySlug = "msoplio"; // or whatever your API expects
    } else if (category === "ჩემი ქალაქი") {
      categorySlug = "chemi-qalaqi"; // or whatever your API expects
    } else {
      categorySlug = slugify(category, { lower: true, strict: true });
    }

    navigate(`/category/${categorySlug}`);
  };

  return (
    <header
      className="for_a_tag"
      style={{ backgroundColor: "#1c1c1d", zIndex: "100" }}
    >
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex items-center">
          <a href="/">
            <img className="h-6 w-auto" src={logo} alt="Logo" />
          </a>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-center lg:gap-x-12">
          <a href="/" className="text-md leading-6 text-white">
            მთავარი
          </a>
          <a href="/aboutus" className="text-md leading-6 text-white">
            ჩვენ შესახებ
          </a>
          <a
            href="#"
            className="text-md leading-6 text-white"
            onClick={() => handleCategoryClick("პოლიტიკა")}
          >
            პოლიტიკა
          </a>
          <a
            href="#"
            className="text-md leading-6 text-white"
            onClick={() => handleCategoryClick("საზოგადოება")}
          >
            საზოგადოება
          </a>
          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-md leading-6 text-white">
              ყველა
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray"
                aria-hidden="true"
              />
            </PopoverButton>
            <Transition
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel className="absolute left-1/2 top-full z-[9999999] mt-3 w-screen max-w-xs -translate-x-1/2 transform overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-2">
                  {products.map((item) => (
                    <div key={item.name}>
                      <div className="flex-auto">
                        <a
                          href="#"
                          className="dropdown"
                          onClick={() => handleCategoryClick(item.name)}
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </PopoverPanel>
            </Transition>
          </Popover>
        </div>
        <div className="lg:flex lg:items-center">
          <button className="searchBtn" onClick={() => setSearchVisible(true)}>
            <FontAwesomeIcon icon={faSearch} className="w-4 h-auto" />
          </button>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </nav>
      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-6 w-auto" src={logo} alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <a href="/" className="mobileDropdown">
                  მთავარი
                </a>
                <a href="/aboutus" className="mobileDropdown">
                  ჩვენ შესახებ
                </a>
                <a href="#" className="mobileDropdown">
                  პოლიტიკა
                </a>
                <a href="#" className="mobileDropdown">
                  საზოგადოება
                </a>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900">
                        ყველა
                        <ChevronDownIcon
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                      </DisclosureButton>
                      <DisclosurePanel className="mt-2 space-y-2">
                        {products.map((item) => (
                          <DisclosureButton
                            key={item.name}
                            as="a"
                            href="#"
                            className="mobileDropdown2"
                            onClick={() => handleCategoryClick(item.name)}
                          >
                            {item.name}
                          </DisclosureButton>
                        ))}
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
      <SearchModal
        searchVisible={searchVisible}
        setSearchVisible={setSearchVisible}
      />
    </header>
  );
}
