import React, { useState, useEffect } from "react";
import Header from "../Header";
import LatestNews from "../Main/LatestNews";
import "../NewsPage/style.css";
import "../Main/LatestNews/LatestNews.css";
import ScrollToTop from "../ScrollToTop/index.jsx";
import Footer from "../Footer/index.jsx";
import useIsMobile from "../../hooks/resize";
import { useLocation, useNavigate } from "react-router-dom";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import slugify from "slugify";

const NewsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { newsItem } = location.state;
  const {
    title,
    date,
    image,
    text,
    category_name,
    video,
    custom_order,
    youtube_link,
  } = newsItem;
  const isMobile = useIsMobile();

  const [similarNews, setSimilarNews] = useState([]);

  useEffect(() => {
    // Scroll to top when the component mounts or when a similar news item is clicked
    window.scrollTo(0, 0);

    // Fetch the detailed news page and increment the view count
    fetch(`http://127.0.0.1:8000/api/blogpages/${custom_order}/`)
      .then((response) => response.json())
      .then((data) => {
        console.log("News detail fetched, views incremented", data);
      })
      .catch((error) => console.error("Error fetching news detail:", error));

    // Fetch similar news based on the current news' category
    fetch("http://127.0.0.1:8000/api/blogpages/")
      .then((response) => response.json())
      .then((data) => {
        const filteredNews = data
          .filter(
            (item) =>
              item.category_name === category_name && item.title !== title
          )
          .slice(0, 6); // Limit to a maximum of 6 similar news items
        setSimilarNews(filteredNews);
      });
  }, [category_name, title, custom_order]); // Ensure useEffect re-runs when category_name, title, or custom_order changes

  const handleNewsClick = (news) => {
    const slug = slugify(news.title, { lower: true, strict: true });
    navigate(`/news/${slug}`, { state: { newsItem: news } });
  };

  const handleTagClick = (tag) => {
    const slugifiedTag = slugify(tag, { lower: true, strict: true }); // Slugify the tag
    navigate(`/tags/${slugifiedTag}`, { state: { originalTag: tag } }); // Navigate using the slugified tag, pass original tag via state
  };

  // Categories that should only display the title, YouTube video, date, and tags
  const limitedDisplayCategories = ["გადაცემები", "ვიდეობლოგი", "ინტერვიუ"];

  return (
    <>
      <Header />
      <div className="whole_news_cont">
        <div className="left-side">
          <div className="headline">
            <h1>{title}</h1>
            <div className="underline"></div>
          </div>

          {/* Check if the category is one of the limited display categories */}
          {limitedDisplayCategories.includes(category_name) ? (
            <>
              {youtube_link && (
                <iframe
                  width="100%"
                  height="400"
                  src={`https://www.youtube.com/embed/${
                    youtube_link.split("v=")[1]
                  }`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ marginTop: "20px" }}
                ></iframe>
              )}
              <p className="news-date-2">
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  style={{ color: "#ababab", marginRight: "5px" }}
                />
                {new Date(date).toLocaleDateString()}
              </p>
              <div className="categories">
                {image.tags.map((tags, index) => (
                  <button key={index} className="category-button">
                    {tags}
                  </button>
                ))}
              </div>
            </>
          ) : (
            <>
              {/* Default display for all other categories */}
              <img src={image.file} alt={newsItem.title} />
              <div className="news_text">
                <div dangerouslySetInnerHTML={{ __html: text }} />
                {video && (
                  <video
                    src={video.file}
                    controls
                    style={{ width: "100%", marginTop: "20px", height: "auto" }}
                    alt={image.tags.join(" ")}
                  />
                )}
                {youtube_link && (
                  <iframe
                    width="100%"
                    height="400"
                    src={`https://www.youtube.com/embed/${
                      youtube_link.split("v=")[1]
                    }`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ marginTop: "20px" }}
                  ></iframe>
                )}
                <p className="news-date-2">
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    style={{ color: "#ababab", marginRight: "5px" }}
                  />
                  {new Date(date).toLocaleDateString()}
                </p>
                <div className="categories">
                  {image.tags.map((tag, index) => (
                    <button
                      key={index}
                      className="category-button"
                      onClick={() => handleTagClick(tag)} // Call handleTagClick when a tag is clicked
                    >
                      {tag}
                    </button>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="right-side">
          <h3>მსგავსი სიახლეები</h3>
          <div className="similar-news">
            {similarNews.map((news, index) => (
              <div
                className="similar-news-item"
                key={index}
                onClick={() => handleNewsClick(news)}
              >
                <img src={news.image.file} alt={news.image.tags.join(" ")} />
                <div className="similar-news-item-date-text">
                  <div className="similar-news-item-date">
                    {new Date(news.date).toLocaleDateString()}
                  </div>
                  <div className="similar-news-item-text">{news.title}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </>
  );
};

export default NewsPage;
