import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";
import slugify from "slugify";
import Header from "../Header";
import ScrollToTop from "../ScrollToTop";
import Footer from "../Footer";
import "./style.css";

// Mapping between category slugs and human-readable names
const categoryNameMap = {
  politika: "პოლიტიკა",
  sazogadoeba: "საზოგადოება",
  janmrteloba: "ჯანმრთელობა",
  ekonomika: "ეკონომიკა",
  kultura: "კულტურა",
  sporti: "სპორტი",
  "chemi-qalaqi": "ჩემი ქალაქი",
  msoplio: "მსოფლიო",
  interviu: "ინტერვიუ",
  gadatsemebi: "გადაცემები",
  videoblogi: "ვიდეობლოგი",
};

const CatPageMain = () => {
  const { categorySlug } = useParams(); // Get the category slug from the URL
  const navigate = useNavigate();
  const [newsData, setNewsData] = useState([]);
  const [dotColor, setDotColor] = useState("#000"); // Default color if none found
  const [adData, setAdData] = useState(null); // State to hold the ad data

  // Convert the slugified category to the human-readable category name
  const categoryName = categoryNameMap[categorySlug] || "";

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);

    // Fetch the news data from your API based on the category name
    if (categoryName) {
      fetch("http://127.0.0.1:8000/api/blogpages/")
        .then((response) => response.json())
        .then((data) => {
          // Filter news based on the category name
          const filteredNews = data.filter(
            (newsItem) => newsItem.category_name === categoryName
          );
          setNewsData(filteredNews);

          // If news items are found, get the dot color from the first item
          if (filteredNews.length > 0) {
            setDotColor(filteredNews[0].dot);
          }
        })
        .catch((error) => console.error("Error fetching news:", error));
    }

    // Fetch the advertisement data from your API
    fetch("http://127.0.0.1:8000/api/advertisements/")
      .then((response) => response.json())
      .then((data) => {
        // Find the advertisement with ad_number 2
        const ad = data.find((ad) => ad.ad_number === 2);
        setAdData(ad);
      })
      .catch((error) => console.error("Error fetching advertisement:", error));
  }, [categoryName]);

  // Determine how many items to show in the slider
  const sliderItemsCount = 5;
  const sliderItems = newsData.slice(0, sliderItemsCount);
  const cardItems = newsData.slice(sliderItemsCount);

  // Function to determine the media type
  const renderAdMedia = (url) => {
    const extension = url.split(".").pop().toLowerCase();
    if (["mp4", "webm", "ogg"].includes(extension)) {
      return (
        <video autoPlay loop muted playsInline className="ad-media">
          <source src={url} type={`video/${extension}`} />
          Your browser does not support the video tag.
        </video>
      );
    } else if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
      return <img src={url} alt="Advertisement" className="ad-media" />;
    } else {
      return <p>Unsupported media type</p>;
    }
  };

  // Handle click on news item
  const handleNewsClick = (newsItem) => {
    const slug = slugify(newsItem.title, { lower: true, strict: true });
    navigate(`/news/${slug}`, { state: { newsItem } });
  };

  return (
    <>
      <Header />
      <div className="main-container4">
        <div className="categ4">
          <div
            className="circle4"
            style={{ backgroundColor: dotColor }} // Apply the dot color to the circle
          ></div>
          <p className="human4">{categoryName}</p>{" "}
          {/* Display the human-readable category name */}
        </div>
        <div
          className="line4"
          style={{ backgroundColor: dotColor }} // Apply the dot color to the line
        ></div>

        {/* Container for Swiper Slider and Advertisement */}
        <div className="slider-ad-container">
          {/* Add the Swiper Slider here */}
          <div className="swiperSlider">
            <Swiper
              direction={"vertical"}
              pagination={{
                clickable: true,
                el: ".swiper-pagination", // Use custom pagination class
                bulletClass: "swiper-pagination-bullet", // Custom bullet class
                bulletActiveClass: "swiper-pagination-bullet-active", // Custom active bullet class
              }}
              autoplay={{
                delay: 5000, // Change slide every 5 seconds
                disableOnInteraction: false, // Continue autoplay after user interaction
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
            >
              {sliderItems.map((newsItem, index) => (
                <SwiperSlide
                  key={index}
                  onClick={() => handleNewsClick(newsItem)}
                >
                  <div className="slide-content">
                    <img
                      src={newsItem.image.file}
                      alt={newsItem.title}
                      className="slide-image"
                    />
                    <div className="black-overlay"></div>
                    <div className="text-overlay">
                      <p className="slide-date">{newsItem.date}</p>
                      <h2 className="slide-title">{newsItem.title}</h2>
                      <p className="slide-intro">{newsItem.intro}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              <div className="swiper-pagination"></div>
            </Swiper>
          </div>

          {/* Advertisement Section */}
          <div className="advertisement">
            {adData ? (
              <a href={adData.link} target="_blank" rel="noopener noreferrer">
                {renderAdMedia(adData.ad_media_url)}
              </a>
            ) : (
              <p>Loading advertisement...</p>
            )}
          </div>
        </div>

        {/* Remaining news cards below */}
        <div className="news-container4">
          {cardItems.map((newsItem, index) => (
            <div
              className="news-card4"
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() => handleNewsClick(newsItem)}
            >
              <img src={newsItem.image.file} alt={newsItem.title} />
              <div className="card-content4">
                <h2>{newsItem.title}</h2>
                <p className="description4">{newsItem.intro}</p>
                <p className="date-of-card4">{newsItem.date}</p>
                <a className="read-more4">სრულად →</a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </>
  );
};

export default CatPageMain;
