import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import "./NewsFeed.css";

const NewsFeed = ({ setDisplayedNewsIds }) => {
  const [mainNews, setMainNews] = useState([]);
  const [sideNews, setSideNews] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the news data from your API
    fetch("https://backend.zmna.ge/api/blogpages/")
      .then((response) => response.json())
      .then((data) => {
        // Filter out news items with in_slider set to true
        const filteredData = data.filter((newsItem) => !newsItem.in_slider);

        // Sort the news data by date and time (newest first)
        const sortedData = filteredData.sort((a, b) => {
          const dateA = new Date(`${a.date}T${a.time}`);
          const dateB = new Date(`${b.date}T${b.time}`);
          return dateB - dateA;
        });

        // Get the first 5 news items for the main feed
        const latestFiveNews = sortedData.slice(0, 5);
        setMainNews(latestFiveNews);

        // Get the next 10 news items for the side feed
        const sideNewsItems = sortedData.slice(5, 15);
        setSideNews(sideNewsItems);

        // Collect only the IDs of the displayed news items
        const displayedIds = [
          ...latestFiveNews.map((news) => news.id),
          ...sideNewsItems.map((news) => news.id),
        ];

        setDisplayedNewsIds(displayedIds);
      })
      .catch((error) => console.error("Error fetching news:", error));
  }, [setDisplayedNewsIds]);

  const handleNewsClick = (newsItem) => {
    const slug = slugify(newsItem.title, { lower: true, strict: true });
    navigate(`/news/${slug}`, {
      state: { newsItem, category: "უახლესი ამბები" },
    });
  };

  return (
    <div className="news-feed-main">
      <div className="news-feed">
        <div className="top_feed">
          <div
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: "#4f46e5",
            }}
          ></div>
          <h2 className="title_cat">უახლესი ამბები</h2>
          <div
            className="see-all p-0 m-0"
            onClick={() =>
              navigate("/category/uaxlesi-ambebi", {
                state: { category: "უახლესი ამბები" },
              })
            }
            style={{
              cursor: "pointer",
              color: "#4F46E5",
              textDecoration: "none",
            }}
          >
            სრულად →
          </div>
        </div>
        {mainNews.length > 0 && (
          <div
            className="header"
            onClick={() => handleNewsClick(mainNews[0])}
            style={{ cursor: "pointer" }}
          >
            <img
              src={mainNews[0].image.file}
              alt={mainNews[0].image.title}
              className="main-image"
            />
            <div className="main-content">
              <div className="main-details">
                <span className="source">{mainNews[0].category_name}</span>
              </div>
              <div className="main-title">{mainNews[0].title}</div>
              <div className="main-description2">{mainNews[0].intro}</div>
              <div style={{ display: "flex", gap: "10px" }}>
                <div className="news-category">
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    style={{ marginRight: "7px" }}
                  />
                  {mainNews[0].date}
                </div>
                <div className="news-category">
                  <FontAwesomeIcon
                    icon={faClock}
                    style={{ marginRight: "7px" }}
                  />
                  {mainNews[0].time}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="header-underline"></div>
        <div className="latest-news">
          <div className="news-cards">
            {mainNews.slice(1).map((newsItem, index) => (
              <div
                className="news-card"
                key={index}
                onClick={() => handleNewsClick(newsItem)}
                style={{ cursor: "pointer" }}
              >
                <img src={newsItem.image.file} alt={newsItem.image.title} />
                <div className="news-content">
                  <div className="news-source-time">
                    <span className="source">{newsItem.category_name}</span>
                  </div>
                  <div className="news-title">{newsItem.title}</div>
                  <div className="news-category">{newsItem.date}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="news-feed-side">
        {sideNews.map((newsItem, index) => (
          <div
            className="side-news-card"
            key={index}
            onClick={() => handleNewsClick(newsItem)}
            style={{ cursor: "pointer" }}
          >
            <div className="source">
              <div
                className="cat"
                style={{
                  backgroundColor: newsItem.dot,
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  display: "inline-block",
                  marginRight: "5px",
                }}
              ></div>
              {newsItem.category_name}
            </div>
            <div className="side-news-title">{newsItem.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsFeed;
