import React from "react";
import "./App.css";
import Home from "./pages/index";
import NewsPage from "./components/NewsPage";
import CatPage from "./components/CatPage";
import SearchPage from "./components/Header/SearchPage";
import AboutUs from "./components/AboutUs/index";
import { Routes, Route } from "react-router-dom";
import CatPageMain from "./components/CatPageMain";
import TagsPage from "./components/TagsPage";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/category/uaxlesi-ambebi" element={<CatPage />} />
        {/* Dynamic category slug route */}
        <Route path="/category/:categorySlug" element={<CatPageMain />} />
        <Route path="/news/:slug" element={<NewsPage />} />
        <Route path="/tags/:tagName" element={<TagsPage />} />
      </Routes>
    </div>
  );
}

export default App;
