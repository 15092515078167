import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import slugify from "slugify"; // Import slugify
import NewsCard from "./NewsCard/index";
import "./LatestNews.css";
import useIsMobile from "../../../hooks/resize";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Navigation, Autoplay } from "swiper/modules";
import Arrow from "../../../icons/arrow-drop-down-line.svg";

export default function LatestNews() {
  const isMobile = useIsMobile();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [latestNews, setLatestNews] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    // Fetch the news data from your API
    fetch("http://127.0.0.1:8000/api/blogpages/")
      .then((response) => response.json())
      .then((data) => {
        // Sort the news data by views in descending order
        const sortedData = data.sort((a, b) => b.views - a.views);
        setLatestNews(sortedData);
      })
      .catch((error) => console.error("Error fetching news:", error));
  }, []);

  const handleNewsClick = (newsItem) => {
    const slug = slugify(newsItem.title, { lower: true, strict: true });
    navigate(`/news/${slug}`, { state: { newsItem } }); // Navigate to news page
  };

  return (
    <div className="main_container">
      <div className="latest_news_section">
        {isMobile ? (
          <>
            <div className="controller_wrapper">
              <h2 className="latest_news_section_title">ტრენდული ამბები</h2>
            </div>
            <div className="latest_news_wrapper">
              <Swiper
                breakpoints={{
                  320: {
                    slidesPerView: 2,
                    spaceBetween: "150px",
                  },
                  375: {
                    slidesPerView: 2,
                    spaceBetween: "80px",
                  },
                  530: {
                    slidesPerView: 2,
                    spaceBetween: "50px",
                  },
                  550: {
                    slidesPerView: 3,
                    spaceBetween: "50px",
                  },
                }}
                pagination={{
                  clickable: true,
                }}
                autoplay={{ delay: 2000, disableOnInteraction: false }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {latestNews.slice(0, 7).map((newsItem, index) => (
                  <SwiperSlide key={index}>
                    <div onClick={() => handleNewsClick(newsItem)}>
                      <NewsCard {...newsItem} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </>
        ) : (
          <>
            <div className="controller_wrapper">
              <h2 className="latest_news_section_title">ტრენდული ამბები</h2>
              <div className="swiper-nav-buttons">
                <button ref={prevRef} className="slider_button">
                  <img
                    src={Arrow}
                    alt="arrow"
                    width={25}
                    height={25}
                    style={{ transform: "rotate(90deg)" }}
                  />
                </button>
                <button ref={nextRef} className="slider_button">
                  <img
                    src={Arrow}
                    alt="arrow"
                    width={25}
                    height={25}
                    style={{ transform: "rotate(-90deg)" }}
                  />
                </button>
              </div>
            </div>
            <div className="latest_news_wrapper">
              <Swiper
                navigation={{
                  prevEl: prevRef.current,
                  nextEl: nextRef.current,
                }}
                onBeforeInit={(swiper) => {
                  swiper.params.navigation.prevEl = prevRef.current;
                  swiper.params.navigation.nextEl = nextRef.current;
                }}
                modules={[Navigation, Autoplay]}
                autoplay={{ delay: 2000, disableOnInteraction: false }}
                className="mySwiper"
                breakpoints={{
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: "350px",
                  },
                  1440: {
                    slidesPerView: 4,
                    spaceBetween: "100px",
                  },
                  2048: {
                    slidesPerView: 6,
                    spaceBetween: "100px",
                  },
                }}
              >
                {latestNews.slice(0, 10).map((newsItem, index) => (
                  <SwiperSlide key={index}>
                    <div onClick={() => handleNewsClick(newsItem)}>
                      <NewsCard {...newsItem} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
