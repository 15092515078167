import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import slugify from "slugify";
import { useNavigate } from "react-router-dom";
import "./styleCat.css";

// Mapping of category names to their color codes
const categoryMap = {
  "#FF8CDF": "პოლიტიკა",
  "#FFE500": "საზოგადოება",
  "#00FA0A": "ჯანმრთელობა",
  "#FF7A00": "ეკონომიკა",
  "#FA00FF": "კულტურა",
  "#FF0000": "სპორტი",
  "#2500FF": "ჩემი ქალაქი",
  "#8BF8FF": "მსოფლიო",
  "#0094FF": "ინტერვიუ",
  "#6B2000": "გადაცემები",
  "#D9C2FF": "ვიდეობლოგი",
};

const ThreeCategories = () => {
  const [newsData, setNewsData] = useState({});
  const navigate = useNavigate();

  // Helper function to check if a date is within the last 10 days
  const isWithinLast10Days = (dateString) => {
    const articleDate = new Date(dateString);
    const currentDate = new Date();
    const tenDaysAgo = new Date(
      currentDate.setDate(currentDate.getDate() - 10)
    );
    return articleDate >= tenDaysAgo;
  };

  useEffect(() => {
    // Fetch news data for each category based on color code
    Object.keys(categoryMap).forEach((colorCode) => {
      fetch(
        `http://127.0.0.1:8000/api/category/${encodeURIComponent(colorCode)}/`
      )
        .then((response) => response.json())
        .then((data) => {
          setNewsData((prevState) => ({
            ...prevState,
            [colorCode]: data.filter((newsItem) =>
              isWithinLast10Days(newsItem.date)
            ),
          }));
        })
        .catch((error) =>
          console.error(
            `Error fetching news for ${categoryMap[colorCode]}:`,
            error
          )
        );
    });
  }, []);

  const handleNewsClick = (newsItem) => {
    const slug = slugify(newsItem.title, { lower: true, strict: true });
    navigate(`/news/${slug}`, {
      state: { newsItem, category: newsItem.category_name },
    });
  };

  const handleCategoryClick = (categoryName) => {
    const categorySlug = slugify(categoryName, { lower: true, strict: true });
    navigate(`/category/${categorySlug}`);
  };

  return (
    <>
      {Object.keys(categoryMap).map((colorCode, index) => {
        const categoryName = categoryMap[colorCode];
        const filteredNews = newsData[colorCode] || [];
        const limitedNews = filteredNews.slice(0, 4);

        if (limitedNews.length > 0) {
          return (
            <div className="news-feed" key={index}>
              <div className="top_feed">
                <div
                  className="top_circle"
                  style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    backgroundColor: colorCode, // Use color code directly
                  }}
                ></div>
                <h2
                  className="m-0 p-0"
                  style={{
                    fontSize: "26px",
                    fontFamily: "Tkt-Regular",
                    paddingLeft: "140px",
                  }}
                >
                  {categoryName}
                </h2>
                <div className="see-all p-0 m-0">
                  <a
                    onClick={() => handleCategoryClick(categoryName)}
                    style={{
                      color: "#4F46E5",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    სრულად →
                  </a>
                </div>
              </div>
              <div className="latest-news">
                <div className="news-cards">
                  {limitedNews.map((newsItem, newsIndex) => (
                    <div
                      className="news-card"
                      key={newsIndex}
                      onClick={() => handleNewsClick(newsItem)}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={newsItem.image.file} alt={newsItem.title} />
                      <div className="news-content">
                        <div className="news-title">{newsItem.title}</div>
                        <div className="iconDate">
                          <FontAwesomeIcon
                            icon={faCalendarAlt}
                            style={{ color: "#888" }}
                          />
                          <div className="news-category">{newsItem.date}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        } else {
          return null; // Do not render the category if there are no news items
        }
      })}
    </>
  );
};

export default ThreeCategories;
