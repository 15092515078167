import React, { useState, useEffect } from "react";
import "../Quiz/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import reklama1 from "../../Images/adjarabetAdd.jpg";
import Calendar from "../Calendar";
import useIsMobile from "../../hooks/resize";

const Quiz = () => {
  const isMobile = useIsMobile();

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [quizData, setQuizData] = useState(null);

  // Fetch quiz question and answers from the API
  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const response = await fetch("http://127.0.0.1:8000/api/quizzes/");
        const data = await response.json();
        setQuizData(data[0]); // Assuming you want the first quiz item
      } catch (error) {
        console.error("Error fetching quiz data:", error);
      }
    };

    fetchQuizData();
  }, []);

  // Function to handle answer selection
  const handleAnswerChange = (event) => {
    setSelectedAnswer(event.target.value);
    if (!isSubmitted) {
      setShowSubmitButton(true);
    }
  };

  // Function to handle form submission
  const handleSubmit = () => {
    if (selectedAnswer) {
      setIsSubmitted(true);
      const correctAnswerText = quizData[`answer${quizData.correct_answer}`];
      setIsCorrect(selectedAnswer === correctAnswerText);
    }
  };

  if (!quizData) {
    return <div>Loading...</div>; // Show loading state while fetching data
  }

  const correctAnswerText = quizData[`answer${quizData.correct_answer}`];

  return (
    <>
      {isMobile ? (
        <div
          className="mshobeli"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="main-ad-box">
            <img src={reklama1} alt="" className="reklama1" />
          </div>
          <div className="quiz-main-cont">
            <div className="quiz-inner">
              <p className="quiz-header">დღის კითხვა</p>
              <div className="quiz-question">{quizData.question}</div>
              <div className="answers">
                {[
                  quizData.answer1,
                  quizData.answer2,
                  quizData.answer3,
                  quizData.answer4,
                ].map((answer, index) => (
                  <label key={index} className="custom-radio">
                    <input
                      type="radio"
                      name="quiz-answer"
                      value={answer}
                      onChange={handleAnswerChange}
                      disabled={isSubmitted} // Disable after submission
                    />
                    <span
                      className={`radio-label ${
                        isSubmitted && answer === correctAnswerText
                          ? "correct"
                          : isSubmitted &&
                            selectedAnswer === answer &&
                            answer !== correctAnswerText
                          ? "incorrect"
                          : ""
                      }`}
                      style={{
                        backgroundColor:
                          isSubmitted && answer === correctAnswerText
                            ? "#B9FAD0" // Green background if correct
                            : isSubmitted &&
                              selectedAnswer === answer &&
                              answer !== correctAnswerText
                            ? "#FBCCCC" // Red background if incorrect
                            : isSubmitted && answer === correctAnswerText
                            ? "#B9FAD0" // Green background for correct answer even if selected answer is wrong
                            : "", // Default background
                        borderColor:
                          isSubmitted && answer === correctAnswerText
                            ? "#2AD400" // Green border if correct
                            : isSubmitted &&
                              selectedAnswer === answer &&
                              answer !== correctAnswerText
                            ? "red" // Red border if incorrect
                            : selectedAnswer === answer
                            ? "#6200ea" // Default border color for selected answer
                            : "#ddd", // Default border color
                        color:
                          isSubmitted && answer === correctAnswerText
                            ? "#2AD400" // Green text if correct
                            : isSubmitted &&
                              selectedAnswer === answer &&
                              answer !== correctAnswerText
                            ? "red" // Red text if incorrect
                            : selectedAnswer === answer
                            ? "#6200ea" // Default text color for selected answer
                            : "#bbb", // Default text color
                      }}
                    >
                      {answer}
                    </span>
                  </label>
                ))}
              </div>
              {showSubmitButton && !isSubmitted && (
                <button className="submit-quiz" onClick={handleSubmit}>
                  დადასტურება
                </button>
              )}
              {isSubmitted && isCorrect && (
                <p className="correct-message" style={{ color: "#2AD400" }}>
                  გილოცავთ! თქვენ სწორად გამოიცანით
                </p>
              )}
              {isSubmitted && !isCorrect && selectedAnswer && (
                <p className="incorrect-message" style={{ color: "red" }}>
                  სამწუხაროდ თქვენ არასწორად გამოიცანით
                </p>
              )}
              {!showSubmitButton && !isSubmitted && (
                <p className="error-message">გთხოვთ აირჩიოთ პასუხი!</p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="mshobeli">
          <div className="main-ad-box2">
            <img src={reklama1} alt="" className="reklama1" />
          </div>
          <div className="quiz-main-cont">
            <div className="quiz-inner">
              <p className="quiz-header">დღის კითხვა</p>
              <div className="quiz-question">{quizData.question}</div>
              <div className="answers">
                {[
                  quizData.answer1,
                  quizData.answer2,
                  quizData.answer3,
                  quizData.answer4,
                ].map((answer, index) => (
                  <label key={index} className="custom-radio">
                    <input
                      type="radio"
                      name="quiz-answer"
                      value={answer}
                      onChange={handleAnswerChange}
                      disabled={isSubmitted} // Disable after submission
                    />
                    <span
                      className={`radio-label ${
                        isSubmitted && answer === correctAnswerText
                          ? "correct"
                          : isSubmitted &&
                            selectedAnswer === answer &&
                            answer !== correctAnswerText
                          ? "incorrect"
                          : ""
                      }`}
                      style={{
                        backgroundColor:
                          isSubmitted && answer === correctAnswerText
                            ? "#B9FAD0" // Green background if correct
                            : isSubmitted &&
                              selectedAnswer === answer &&
                              answer !== correctAnswerText
                            ? "#FBCCCC" // Red background if incorrect
                            : isSubmitted && answer === correctAnswerText
                            ? "#B9FAD0" // Green background for correct answer even if selected answer is wrong
                            : "", // Default background
                        borderColor:
                          isSubmitted && answer === correctAnswerText
                            ? "#2AD400" // Green border if correct
                            : isSubmitted &&
                              selectedAnswer === answer &&
                              answer !== correctAnswerText
                            ? "red" // Red border if incorrect
                            : selectedAnswer === answer
                            ? "#6200ea" // Default border color for selected answer
                            : "#ddd", // Default border color
                        color:
                          isSubmitted && answer === correctAnswerText
                            ? "#2AD400" // Green text if correct
                            : isSubmitted &&
                              selectedAnswer === answer &&
                              answer !== correctAnswerText
                            ? "red" // Red text if incorrect
                            : selectedAnswer === answer
                            ? "#6200ea" // Default text color for selected answer
                            : "#bbb", // Default text color
                      }}
                    >
                      {answer}
                    </span>
                  </label>
                ))}
              </div>
              {showSubmitButton && !isSubmitted && (
                <button className="submit-quiz" onClick={handleSubmit}>
                  დადასტურება
                </button>
              )}
              {isSubmitted && isCorrect && (
                <p className="correct-message" style={{ color: "#2AD400" }}>
                  გილოცავთ! თქვენ სწორად გამოიცანით
                </p>
              )}
              {isSubmitted && !isCorrect && selectedAnswer && (
                <p className="incorrect-message" style={{ color: "red" }}>
                  სამწუხაროდ თქვენ არასწორად გამოიცანით
                </p>
              )}
              {!showSubmitButton && !isSubmitted && (
                <p className="error-message">გთხოვთ აირჩიოთ პასუხი!</p>
              )}
            </div>
          </div>

          <div className="added-comps">
            <div className="calendar-container">
              {" "}
              {/* Updated Calendar component */}
              <Calendar />
            </div>
            <div className="quiz-main-cont2">
              <p className="follow-us">გამოგვყევი</p>
              <div className="social-links">
                <div
                  className="facebook-main"
                  style={{ backgroundColor: "#3b5998" }}
                >
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    style={{ color: "white", fontSize: "22px" }}
                  />
                  <span style={{ marginLeft: "8px", color: "white" }}>
                    Zmna.ge
                  </span>
                </div>
                <div
                  className="insta-main"
                  style={{ backgroundColor: "#e1306c" }}
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    style={{ color: "white", fontSize: "22px" }}
                  />
                  <span style={{ marginLeft: "8px", color: "white" }}>
                    Zmna.ge
                  </span>
                </div>
                <div
                  className="tiktok-main"
                  style={{ backgroundColor: "#000000" }}
                >
                  <FontAwesomeIcon
                    icon={faTiktok}
                    style={{ color: "white", fontSize: "22px" }}
                  />
                  <span style={{ marginLeft: "8px", color: "white" }}>
                    Zmna.ge
                  </span>
                </div>
                <div
                  className="youtube-main"
                  style={{ backgroundColor: "#FF0000" }}
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    style={{ color: "white", fontSize: "22px" }}
                  />
                  <span style={{ marginLeft: "8px", color: "white" }}>
                    Zmna.ge
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Quiz;
