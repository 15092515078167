import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./carousel.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";

const MainCarousel = () => {
  const [carouselData, setCarouselData] = useState([]);
  const [sideData, setSideData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://127.0.0.1:8000/api/blogpages/"
        );
        const allData = response.data.filter((item) => item.in_slider);
        setCarouselData(allData.slice(0, 3));
        setSideData(
          allData
            .slice(3)
            .concat(response.data.filter((item) => !item.in_slider))
        );
        setLoading(false); // Set loading to false after data is fetched
        console.log("Fetched data:", allData);
      } catch (error) {
        console.error("Error fetching the data:", error);
      }
    };

    fetchData();
  }, []);

  const handleNewsClick = (newsItem) => {
    const slug = slugify(newsItem.title, { lower: true, strict: true });
    navigate(`/news/${slug}`, { state: { newsItem } });
  };

  const renderCarouselItems = (items) => {
    return items.map((item, index) => (
      <div
        className="position-relative overflow-hidden"
        style={{ height: "500px" }}
        key={index}
        onClick={() => handleNewsClick(item)}
      >
        <img
          className="img-fluid h-100"
          src={item.image.file}
          style={{ objectFit: "cover" }}
          alt={item.image.title}
        />
        <div className="overlay">
          <div className="mb-2">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="cat"
                style={{
                  backgroundColor: item.dot,
                  width: "10px",
                  height: "10px",
                  marginRight: "5px",
                }}
              ></div>
              <a
                className="badge badge-primary text-uppercase font-weight-semi-bold"
                href=""
                style={{ padding: "0 !important", fontSize: "20px" }}
              >
                {item.category_name}
              </a>
              <a
                className="text-white"
                style={{ paddingTop: "5px", fontSize: "15px" }}
                href=""
              >
                {new Date(item.date).toLocaleDateString("en-CA")}
              </a>
            </div>
          </div>
          <a
            className="h2 m-0 text-white text-uppercase font-weight-bold text-left"
            href=""
            style={{ paddingBottom: "20px" }}
          >
            {item.title}
          </a>
        </div>
      </div>
    ));
  };

  const renderSideItems = (items) => {
    return items.slice(0, 4).map((item, index) => (
      <div
        className="col-md-6 px-0"
        key={index}
        onClick={() => handleNewsClick(item)}
      >
        <div
          className="position-relative overflow-hidden"
          style={{ height: "250px" }}
        >
          <img
            className="img-fluid w-100 h-100"
            src={item.image.file}
            style={{ objectFit: "cover" }}
            alt={item.image.title}
          />
          <div className="overlay" style={{ cursor: "pointer" }}>
            <div className="mb-2">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="cat"
                  style={{
                    backgroundColor: item.dot,
                    width: "10px",
                    height: "10px",
                    marginRight: "5px",
                  }}
                ></div>
                <a
                  className="badge badge-primary text-uppercase font-weight-semi-bold"
                  href=""
                  style={{ padding: "0 !important", fontSize: "17px" }}
                >
                  {item.category_name}
                </a>
                <a className="text-white" href="">
                  {new Date(item.date).toLocaleDateString("en-CA")}
                </a>
              </div>
            </div>
            <a
              className="h6 m-0 text-white text-uppercase font-weight-semi-bold"
              href=""
            >
              {item.title}
            </a>
          </div>
        </div>
      </div>
    ));
  };

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator while data is being fetched
  }

  return (
    <div
      className="container-fluid pb-11"
      style={{ fontFamily: "Tkt-Regular" }}
    >
      <div className="row">
        <div className="col-lg-7 px-0">
          <Carousel
            showThumbs={false}
            infiniteLoop
            autoPlay
            showArrows={false}
            interval={3000}
            transitionTime={600}
          >
            {renderCarouselItems(carouselData)}
          </Carousel>
        </div>
        <div className="col-lg-5 px-0">
          <div className="row mx-0">{renderSideItems(sideData)}</div>
        </div>
      </div>
    </div>
  );
};

export default MainCarousel;
