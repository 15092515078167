import React, { useEffect, useState } from "react";
import NewsCategory from "./NewsCategory";
import LatestNews from "./LatestNews";
import Footer from "../Footer";
import "../Main/Main.css";
import ScrollToTop from "../ScrollToTop";
import MainCarousel from "../Carousel/carousel";
import NewsFeed from "../NewsFeed";
import ThreeCategories from "../ThreeCategories";
import MainAd from "../Adds/MainPageAd/index";
import OnPageAd from "../Adds/MainOnPageAd/index";
import Quiz from "../Quiz/index";

export default function Main() {
  const [displayedNewsIds, setDisplayedNewsIds] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []); // Empty dependency array means this runs once on component mount

  return (
    <div className="spacing">
      <MainCarousel />
      {/* <OnPageAd /> */}
      <Quiz />
      <NewsFeed setDisplayedNewsIds={setDisplayedNewsIds} />
      <LatestNews />
      <MainAd />
      <ThreeCategories />
      <NewsCategory displayedNewsIds={displayedNewsIds} />
      <Footer />
      <ScrollToTop />
    </div>
  );
}
