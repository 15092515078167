import React, { useState, useEffect } from "react";
import arrows from "../../icons/arrows.svg";
import "./style.css";

const ScrollToTop = () => {
  const [backToTopButton, setBackToTopButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {backToTopButton && (
        <div className="button" onClick={scrollUp} data-aos="fade-up">
          <div className="background">
            <div className="arrows">
              <img src={arrows} alt="Scroll to top" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ScrollToTop;
